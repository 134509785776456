import { setCulture, loadCldr } from "@syncfusion/ej2-base";
import { L10n } from "@syncfusion/ej2-base";

// Korean locale data
import * as gregorian from "cldr-data/main/ko/ca-gregorian.json";
import * as numbers from "cldr-data/main/ko/numbers.json";
import * as timeZoneNames from "cldr-data/main/ko/timeZoneNames.json";
import * as NumberingSystems from "cldr-data/supplemental/numberingSystems.json";
import * as weekData from "cldr-data/supplemental/weekData.json";

// Load CLDR data
loadCldr(NumberingSystems, gregorian, numbers, timeZoneNames, weekData);

// Load Korean locale strings for Syncfusion Scheduler
L10n.load({
  ko: {
    datepicker: {
      today: "오늘",
    },
    daterangepicker: {
      applyText: "선택",
      cancelText: "취소",
      endLabel: "종료일",
      selectedDays: "기간을 선택해주세요.",
      startLabel: "시작일",
      days: "일",
    },
    dropdowns: {
      noRecordsTemplate: "해당 데이터가 없습니다.",
    },
    schedule: {
      today: "오늘",
      save: "저장",
      cancel: "취소",
      delete: "삭제",
      edit: "편집",
      "New Event": "새 이벤트",
      add: "추가",
      "Edit Event": "이벤트 편집",
      details: "세부 사항",
      "Start Time": "시작 시간",
      "End Time": "종료 시간",
      location: "위치",
      description: "설명",
      week: "주",
      month: "월",
      workWeek: "근무 주",
      day: "일",
    },
  },
});

// Set culture to Korean
setCulture("ko");
