import { Box, Grid, Modal } from "@mui/material";
import {
  ScheduleComponent,
  Month,
  Inject,
  ViewsDirective,
  ViewDirective,
  EventRenderedArgs,
} from "@syncfusion/ej2-react-schedule";
import { Fragment, useEffect, useRef, useState } from "react";
import { MaxWidth, ModalMobileStyle, ModalStyle } from "../../styles/theme";
import {
  AccommodationCircleItem,
  AccommodationEmptyItem,
  ScheduleItemBox,
  ScheduleItemPointerBox,
  ScheduleLegend,
  ScheduleLegendDisabledItem,
  ScheduleLegendItem,
  ScheduleSmallItemBox,
  ScheduleTitle,
} from "../../styles/accommodationStyle";
import { VehicleApi } from "../../system/api/VehicleApi";
import { ErrorHandler } from "../../system/ApiService";
import AlertModal from "../Common/AlertModal";
import { useLoadingDispatch } from "../../system/context/LoadingContext";
import { GRAYPALEDISABLED, PRIMARY } from "../../styles/color";
import { ColorPalette } from "../../system/Constants";
import { ResourceApi } from "../../system/api/Resource";
import { HolidayApi } from "../../system/api/HolidayApi";
import { useRecoilState } from "recoil";
import { HolidayDataAtom } from "../../system/atoms";
import { isoDateFormatter } from "../Common/IsoDateFormatter";
import { CustomButtonGreen } from "../../styles/button";
import VehicleReservation from "./VehicleReservation";

import { MobileSmall, Tablet } from "../../pages/Layout/ResponsiveScreen";

interface LegendProps {
  type: string;
  count: number;
}
interface HolidayProps {
  name: string;
  date: string;
}

function VehicleCalendar() {
  let scheduleObj = useRef<ScheduleComponent | null>(null);
  const [resourceData, setResourceData] = useState<LegendProps[]>();

  const Loading = useLoadingDispatch();
  const [vehicleSizeType, setVehicleSizeType] = useState("");
  const [selectedCalendarDate, setSelectedCalendarDate] = useState(
    new Date(new Date().getTime() + 8 * 7 * 24 * 60 * 60 * 1000)
  );
  const [scheduleData, setScheduleData] = useState<any>(null);

  // 모달 정보
  const [open, setOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState<string | undefined>("");
  const [availableTime, setAvailableTime] = useState("종일");
  const [vehicleId, setVehicleId] = useState<number>(0);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setSelectedDate("");
    setVehicleId(0);
    getVehicleCalendarData(
      vehicleSizeType === "" ? true : false,
      undefined,
      vehicleSizeType
    );
  };
  const openReservationModal = () => {
    setAvailableTime("");
    setOpen(true);
  };

  useEffect(() => {
    ResourceApi.getResource("차량")
      .then((res) => {
        setResourceData(res.data);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, []);

  var todayYear = new Date().getFullYear();
  const [searchHolidayYear, setSearchHolidayYear] = useState(todayYear);
  const [holidayList, setHolidayList] = useRecoilState(HolidayDataAtom);

  const getVehicleCalendarData = (
    isBookmarks: boolean,
    selectedDate?: Date | undefined,
    type?: string | ""
  ) => {
    Loading({ type: "LOADING" });
    VehicleApi.getReservationCalendar(
      type,
      selectedDate
        ? selectedDate.getFullYear()
        : selectedCalendarDate.getFullYear(),
      selectedDate
        ? selectedDate.getMonth() + 1
        : selectedCalendarDate.getMonth() + 1,
      isBookmarks
    )
      .then((res) => {
        setScheduleData(res.data);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      })
      .finally(() => {
        Loading({ type: "COMPLETE" });
      });
  };
  const [, setNavigateData] = useState({});
  const handleDateNavigate = (args: any) => {
    if (args.requestType === "dateNavigate" || "toolBarItemRendered") {
      getReservationCalendar(scheduleObj.current?.selectedDate);
    }
    setNavigateData(args);
  };

  const getReservationCalendar = (selectedDate: Date | undefined) => {
    if (!selectedDate) return;
    setSelectedCalendarDate(selectedDate);
    if (selectedDate.getFullYear() !== searchHolidayYear) {
      HolidayApi.getHoliday(selectedDate.getFullYear()).then((res) => {
        setHolidayList(res.data);
      });
      setSearchHolidayYear(selectedDate.getFullYear());
    }
    if (selectedDate.getMonth() !== selectedCalendarDate.getMonth()) {
      getVehicleCalendarData(
        vehicleSizeType === "" ? true : false,
        selectedDate,
        vehicleSizeType
      );
    }
  };

  const changeRegion = (type: string) => {
    if (type === "자주 사용하는 차량") {
      setVehicleSizeType("");
      getVehicleCalendarData(true, undefined, "");
    } else {
      setVehicleSizeType(type);
      getVehicleCalendarData(false, undefined, type);
    }
  };

  function legendCircle(type: string, color: string, cnt: number) {
    if (type === "자주 사용하는 차량") {
      if (vehicleSizeType === "") {
        return (
          <ScheduleLegendItem
            color={color}
            container
            alignItems="center"
            justifyContent="center"
          >
            {type}
          </ScheduleLegendItem>
        );
      } else {
        return (
          <ScheduleLegendDisabledItem
            color={color}
            container
            alignItems="center"
            justifyContent="center"
          >
            {type}
          </ScheduleLegendDisabledItem>
        );
      }
    } else if (type === vehicleSizeType) {
      return (
        <ScheduleLegendItem
          color={color}
          container
          alignItems="center"
          justifyContent="center"
        >
          {type + " (" + cnt + ")"}
        </ScheduleLegendItem>
      );
    } else {
      return (
        <ScheduleLegendDisabledItem
          color={color}
          container
          alignItems="center"
          justifyContent="center"
        >
          {type + " (" + cnt + ")"}
        </ScheduleLegendDisabledItem>
      );
    }
  }

  const template = (props: any) => {
    if (
      props.Data.IsAvailable === false ||
      (props.Data.IsAmAvailable === false && props.Data.IsPmAvailable === false)
    ) {
      return (
        <ScheduleItemBox color={GRAYPALEDISABLED}>
          <ScheduleSmallItemBox color={GRAYPALEDISABLED}>
            {props.Subject}
          </ScheduleSmallItemBox>
          <AccommodationEmptyItem />
          <AccommodationEmptyItem />
        </ScheduleItemBox>
      );
    } else {
      return (
        <ScheduleItemPointerBox
          color={props.CategoryColor}
          onClick={() => onClickRez(props)}
        >
          <ScheduleSmallItemBox color={props.CategoryColor}>
            {props.Subject}
          </ScheduleSmallItemBox>
          {props.Data.IsAmAvailable ? (
            <AccommodationCircleItem />
          ) : (
            <AccommodationEmptyItem />
          )}
          {props.Data.IsPmAvailable ? (
            <AccommodationCircleItem />
          ) : (
            <AccommodationEmptyItem />
          )}
        </ScheduleItemPointerBox>
      );
    }
  };

  function onDataBinding(e: any) {
    let items = e.result;
    let ScheduleData = [];
    if (items.length > 0) {
      for (var i = 0; i < items.length; i++) {
        ScheduleData.push({
          Id: items[i].Id,
          Subject: items[i].Subject,
          Location: items[i].Subject,
          StartTime: new Date(items[i].StartTime),
          EndTime: new Date(items[i].EndTime),
          IsAllDay: true,
          CategoryColor: items[i].CategoryColor
            ? items[i].CategoryColor
            : "#000000",
          Data: items[i],
        });
      }
    }

    e.result = ScheduleData;
  }

  const onPopupOpen = (args: any) => {
    args.cancel = true;
  };

  const getHoliday = (value: Date) => {
    var foundDate: HolidayProps[] = holidayList.filter(
      (e: HolidayProps) => e.date === isoDateFormatter(value)
    );
    if (foundDate.length > 0) {
      return `<div class="testClass">${foundDate[0].name}</div>`;
    } else {
      return "<div></div>";
    }
  };

  const onRenderCell = (args: any) => {
    if (args.elementType === "monthCells" && window.innerWidth >= 650) {
      let ele = document.createElement("div");
      if (getHoliday(args.date)) {
        ele.innerHTML = getHoliday(args.date);
      }
      args.element.appendChild(ele.firstChild);
    }
  };

  const onClickRez = (dt: any) => {
    if (dt.Data.IsAmAvailable && dt.Data.IsPmAvailable) {
      setAvailableTime("종일");
    } else {
      if (dt.Data.IsAmAvailable) {
        setAvailableTime("오전");
      } else {
        setAvailableTime("오후");
      }
    }
    setSelectedDate(
      dt.Data.StartTime
        ? isoDateFormatter(new Date(dt.Data.StartTime))
        : undefined
    );
    setVehicleId(dt.Data.VehicleId);
    handleOpen();
  };

  function onEventRendered(args: EventRenderedArgs): void {
    let categoryColor: string = args.data.CategoryColor as string;

    if (!args.element || !categoryColor) {
      return;
    } else {
      args.element.style.border = "0px";
      args.element.style.backgroundColor = "transparent";
      args.element.style.color = categoryColor;
      args.element.style.height = "25px";
      args.element.style.marginTop = `${args?.data?.Index * 1}px`;
    }
  }

  return (
    <MaxWidth>
      <Grid container item xs={12} mt="10px">
        <ScheduleTitle container spacing={2}>
          <Grid item xs={12}>
            <ScheduleLegend
              item
              xs={12}
              container
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                item
                onClick={() => changeRegion("자주 사용하는 차량")}
                margin="5px"
              >
                {legendCircle("자주 사용하는 차량", PRIMARY, 0)}
              </Grid>

              {resourceData &&
                resourceData.map((item: LegendProps, idx: number) => (
                  <Grid
                    key={idx}
                    onClick={() => changeRegion(item.type)}
                    item
                    margin="5px"
                  >
                    {legendCircle(
                      item.type,
                      ColorPalette[2 * idx].color,
                      item.count
                    )}
                  </Grid>
                ))}
            </ScheduleLegend>
          </Grid>
        </ScheduleTitle>
      </Grid>
      <Grid container item xs={12} justifyContent="right">
        <CustomButtonGreen onClick={openReservationModal}>
          차량 예약
        </CustomButtonGreen>
      </Grid>
      <Grid container item xs={12} mt="10px">
        <ScheduleComponent
          minDate={
            new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate()
            )
          }
          currentView="Month"
          actionComplete={handleDateNavigate}
          eventSettings={{
            dataSource: scheduleData,
            template: template as any,
            ignoreWhitespace: false,
          }}
          ref={scheduleObj}
          readonly={true}
          locale="ko"
          rowAutoHeight={true}
          dataBinding={onDataBinding}
          eventRendered={onEventRendered}
          popupOpen={onPopupOpen}
          renderCell={onRenderCell}
        >
          <ViewsDirective>
            <ViewDirective option="Month" />
          </ViewsDirective>
          <Inject services={[Month]} />
        </ScheduleComponent>
      </Grid>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Fragment>
          <MobileSmall>
            <Box sx={ModalMobileStyle}>
              <VehicleReservation
                handleClose={handleClose}
                vehicleId={vehicleId}
                selectedDate={selectedDate}
                availableTime={availableTime ? availableTime : ""}
              />
            </Box>
          </MobileSmall>
          <Tablet>
            <Box sx={ModalStyle}>
              <VehicleReservation
                handleClose={handleClose}
                vehicleId={vehicleId}
                selectedDate={selectedDate}
                availableTime={availableTime ? availableTime : ""}
              />
            </Box>
          </Tablet>
        </Fragment>
      </Modal>
    </MaxWidth>
  );
}

export default VehicleCalendar;
